import { React, useEffect, useRef } from "react";
import "./App.js.css";

function CheckBox(props) {
  const handleMouseOver = (e) => {
    if (e.target.checked) {
      e.target.removeAttribute("checked");
    } else {
      e.target.setAttribute("checked", true);
    }
  };

  return <input type='checkbox' onMouseOver={handleMouseOver} />;
}

function SpanBox(props) {
  const r = useRef(null);
  const handleMouseOver = (e) => {
    if (e.target.classList.length > 0) {
      e.target.classList.remove("hovered");
    } else {
      e.target.classList.add("hovered");
      setTimeout(() => {
        e.target.classList.remove("hovered");
      }, 2000 + Math.random() * 3000);
    }
  };

  const span = <span onMouseOver={handleMouseOver} ref={r} />;

  useEffect(() => {
    if (Math.random() < 0.9) {
      setTimeout(() => {
        r.current.className = "hovered";
        setTimeout(() => {
          r.current.className = "";
        }, 500 + Math.random() * 5000);
      }, Math.random() * 500);
    }
  });

  return span;
}

function App() {
  const createCheckBoxes = (numCheckBoxes) => {
    console.log("Creating spanboxes!");
    return [...Array(numCheckBoxes).keys()].map((ID) => {
      return <CheckBox key={ID} id={ID} />;
    });
  };

  const createSpankBoxes = (numSpanBoxes) => {
    console.log("Creating checkboxes!");
    return [...Array(numSpanBoxes).keys()].map((ID) => {
      return <SpanBox key={ID} id={ID} />;
    });
  };

  // return <div className='App'>{createCheckBoxes(10000)}</div>;
  return <div className='App'>{createSpankBoxes(15000)}</div>;
}

export default App;
